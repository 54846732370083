<template>
  <div
    class="w-full relative !cursor-pointer"
    :class="[
      fullWidth === 'true' ? '' : 'lg:max-w-[396px]',
      disabled ? 'cursor-not-allowed opacity-50' : '',
    ]"
    v-outside
  >
    <div
      class="flex flex-col w-full"
      :class="[
        fullWidth === 'true' ? 'w-full' : width ? width : 'md:max-w-[396px]',
        label ? 'gap-3' : '',
      ]"
    >
      <p
        class="font-semibold text-sm text-neutral-800 dark:text-neutral-200"
        :class="textClass ? textClass : 'leading-none'"
      >
        {{ label }}
      </p>
      <div
        class="flex items-center bg-transparent"
        @click.prevent="showTimePickerContainer()"
      >
        <!-- @click.prevent="showTimePicker = !showTimePicker" -->
        <div
          class="inline-flex w-full items-center justify-between gap-x-1.5 rounded-md bg-white text-sm font-medium text-neutral-600 border dark:bg-neutral-800 outline-none hover:border-neutral-400 transition-all ease-in-out duration-150"
          @click="clicked = true"
          :class="[
            error
              ? 'border-red-500'
              : moreClass
              ? moreClass
              : disabled
              ? 'cursor-not-allowed'
              : 'border-neutral-300 dark:border-neutral-700',
            customPadding ? customPadding : ' p-[11px] ',
            clicked ? 'border-blue-600' : '',
          ]"
        >
          <input
            type="time"
            :value="computedTime"
            @change="updateTime"
            class="focus:outline-none !cursor-pointer dark:bg-neutral-800 text-neutral-600 dark:text-neutral-400"
          />

          <svg
            width="20"
            class="text-neutral-600 dark:text-neutral-400 cursor-pointer"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 5V10H13.75M17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      class="w-full bg-white dark:bg-neutral-800 absolute border dark:border-neutral-700 z-30 rounded-md overflow-hidden"
      :class="appearOnTop ? '-mt-52' : 'mt-2'"
      v-if="showTimePicker"
    >
      <div
        class="w-full h-full flex font-normal text-sm leading-none text-neutral-600"
      >
        <!-- Hours -->
        <div
          class="w-full h-[150px] overflow-auto content-scroll-new text-center flex flex-col border-r border-neutral-200 dark:border-neutral-700"
          ref="hoursContainer"
        >
          <div
            @click="handleTime((getHours = item), getMinutes, getSeconds, amPM)"
            class="py-3"
            :class="[
              (getHours == item || (getHours == '12' && item == '00')) &&
                'bg-blue-600 text-white',
              getHours != item &&
                !(getHours == '12' && item == '00') &&
                'hover:bg-neutral-100 dark:hover:bg-blue-400/10 text-neutral-600 dark:text-neutral-400',
            ]"
            v-for="item in hoursArray"
            :key="item"
            ref="hourItem"
          >
            <p class="cursor-pointer">{{ item == "00" ? "12" : item }}</p>
          </div>
        </div>
        <!-- Minutes -->
        <div
          class="w-full h-[150px] overflow-auto content-scroll-new text-center flex flex-col border-r border-neutral-200 dark:border-neutral-700"
          ref="minutesContainer"
        >
          <div
            @click="handleTime(getHours, (getMinutes = item), getSeconds, amPM)"
            class="py-3"
            :class="[
              getMinutes == item && 'bg-blue-600 text-white',
              getMinutes != item &&
                'hover:bg-neutral-100 dark:hover:bg-blue-400/10 text-neutral-600 dark:text-neutral-400',
            ]"
            v-for="item in minutesArray"
            :key="item"
            ref="minuteItem"
          >
            <p class="cursor-pointer">{{ item }}</p>
          </div>
        </div>
        <!-- Seconds -->
        <div
          v-if="hasSeconds"
          class="w-full h-[150px] overflow-auto content-scroll-new text-center flex flex-col border-r border-neutral-200 dark:border-neutral-700"
          ref="secondsContainer"
        >
          <div
            @click="handleTime(getHours, getMinutes, (getSeconds = item), amPM)"
            class="py-3"
            :class="[
              getSeconds == item && 'bg-blue-600 text-white',
              getSeconds != item &&
                'hover:bg-neutral-100 dark:hover:bg-blue-400/10 text-neutral-600 dark:text-neutral-400',
            ]"
            v-for="item in secondsArray"
            :key="item"
            ref="secondItem"
          >
            <p class="cursor-pointer">{{ item }}</p>
          </div>
        </div>
        <!-- AM/PM -->
        <div class="w-full text-center flex flex-col">
          <div
            @click="handleTime(getHours, getMinutes, getSeconds, (amPM = item.title))"
            class="py-3"
            :class="[
              amPM == item.title && 'bg-blue-600  text-white',
              amPM != item.title &&
                'hover:bg-neutral-100 dark:hover:bg-blue-400/10 text-neutral-600 dark:text-neutral-400',
            ]"
            v-for="item in ampmArray"
            :key="item"
          >
            <p class="cursor-pointer">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <!-- <p
        class="font-bold text-sm leading-none text-blue-600 py-3 pr-3 text-end border-t border-neutral-200 cursor-pointer"
        @click="showTimePicker = false"
      >
        OK
      </p> -->
    </div>
  </div>
</template>
<script setup>
// Props

const props = defineProps({
  value: String,
  fullWidth: String,
  width: String,
  label: String,
  test: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  moreClass: String,
  error: { type: Boolean, default: false },
  customPadding: String,
  fit: {
    type: Boolean,
    default: false,
  },
  appearOnTop: Boolean,
  showRelative: { type: Boolean, default: false },
  get12Hour: { type: Boolean, default: false },
  hasSeconds: { type: Boolean, default: false },
});

// Emits

const emit = defineEmits(["setTime, setHours, setMinutes, setSeconds, setAMPM"]);

// Refs

const currentTime = ref(null);
const showTimePicker = ref(false);
const clicked = ref(false);
// const defaultTime = ref("02:10");
const getHours = ref("01");
const getMinutes = ref("00");
const getSeconds = ref("00");
const amPM = ref("AM");
const getTime = ref("00:00:00 AM");
const secondsContainer = ref(null);
const minutesContainer = ref(null);
const hoursContainer = ref(null);
const secondItem = ref([]);
const minuteItem = ref([]);
const hourItem = ref([]);

// Methods

const addLeadingZero = (value) => {
  return value < 10 ? `0${value}` : value.toString();
};

const handleTime = (hours, minutes, seconds, amOrPM) => {
  if (props.get12Hour && hours == "00") {
    hours = 12;
  }

  if (minutes == null) {
    minutes = "00";
  } else if (minutes.length < 2) {
    minutes = "0" + minutes;
  }
  if (seconds == null) {
    seconds = "00";
  } else if (seconds.length < 2) {
    seconds = "0" + seconds;
  }
  if (hours == null) {
    hours = "12";
  }
  emit("setHours", hours);
  emit("setMinutes", minutes);
  emit("setSeconds", seconds);
  emit("setAMPM", amOrPM);
  if (amOrPM == "Relative") amPM.value = "AM";
  let time = hours + ":" + minutes;
  if (props.hasSeconds) {
    time += ":" + seconds;
  }
  time += " " + amOrPM;
  getTime.value = time;

  emit("setTime", getTime.value);
};
const updateTime = (e) => {
  if (e?.target?.value?.split(":")[0] > 12) {
    getHours.value = e.target.value.split(":")[0] - 12;
    amPM.value = "PM";
  } else {
    getHours.value = e?.target?.value?.split(":")[0] || "01";
    amPM.value = "AM";
  }
  getMinutes.value = e?.target?.value?.split(":")[1]?.split(" ")[0] || "00";
  getSeconds.value = e?.target?.value?.split(":")[2]?.split(" ")[0] || "00";
  handleTime(getHours.value, getMinutes.value, getSeconds.value, amPM.value);
};

const loadSettings = () => {
  if (props.value) {
    currentTime.value = props.value;
    let minutes = props?.value?.split(":")[1]?.split(" ")[0];
    if (minutes == null) {
      getMinutes.value = "00";
    } else if (minutes.length < 2) {
      getMinutes.value = "0" + minutes;
    } else getMinutes.value = minutes;
    let hours = props?.value?.split(":")[0];
    if (hours == null || hours == "12") {
      getHours.value = "00";
    } else getHours.value = hours;
    let seconds = props?.value?.split(":")[2]?.split(" ")?.[0];
    let amPmIndex = typeof seconds === "undefined" ? 1 : 2;
    amPM.value = props?.value?.split(":")[amPmIndex]?.split(" ")?.[1] || "AM";
    if (seconds == null) {
      getSeconds.value = "00";
    } else if (seconds.length < 2) {
      getSeconds.value = "0" + seconds;
    } else getSeconds.value = seconds;
  }
};

// Setting values for hours, minutes and AM/PM

const minutesArray = Array.from({ length: 60 }, (_, i) => addLeadingZero(i));
const secondsArray = Array.from({ length: 60 }, (_, i) => addLeadingZero(i));
const hoursArray = Array.from({ length: 12 }, (_, i) => addLeadingZero(i));
hoursArray.splice(13, 0, hoursArray.splice(0, 1)[0]);
const ampmArray = computed(() => {
  if (props.showRelative) {
    return [
      { title: "AM", value: 0 },
      { title: "PM", value: 1 },
      { title: "Relative", value: "page load" },
    ];
  }
  return [
    { title: "AM", value: 0 },
    { title: "PM", value: 1 },
  ];
});
const selectedElement = () => {
  const selectedMinuteElement = minuteItem.value.find((item) =>
    item.classList.contains("bg-blue-600")
  );
  if (selectedMinuteElement) {
    const minuteContainer = minutesContainer.value;
    const minuteOffsetTop = selectedMinuteElement.offsetTop;
    minuteContainer.scrollTop = minuteOffsetTop - 55;
  }
  const selectedHourElement = hourItem.value.find((item) =>
    item.classList.contains("bg-blue-600")
  );
  if (selectedHourElement) {
    const hourContainer = hoursContainer.value;
    const hourOffsetTop = selectedHourElement.offsetTop;
    hourContainer.scrollTop = hourOffsetTop - 55;
  }
  const selectedSecondElement = secondItem.value.find((item) =>
    item.classList.contains("bg-blue-600")
  );
  if (selectedSecondElement) {
    const secondContainer = secondsContainer.value;
    const secondOffsetTop = selectedSecondElement.offsetTop;
    secondContainer.scrollTop = secondOffsetTop - 55;
  }
};
const showTimePickerContainer = () => {
  showTimePicker.value = !showTimePicker.value;
  if (showTimePicker.value) {
    nextTick(() => {
      selectedElement();
    });
  }
};

// Lifecycle functions
watch(
  () => props.value,
  () => {
    loadSettings();
  },
  { immediate: true }
);
// Close on click outside

const vOutside = {
  mounted(el, binding, vnode) {
    const onClickOutside = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        showTimePicker.value = false;
      }
    };
    setTimeout(() => {
      document.body.addEventListener("click", onClickOutside);
      el._onClickOutside = onClickOutside;
    }, 2000);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el._onClickOutside);
    delete el._onClickOutside;
  },
};

const computedTime = computed(() => {
  let formatted = amPM.value == 'AM'
                ? `${getHours.value}:${getMinutes.value}`
                : `${(parseInt(getHours.value) + 12).toString()}:${getMinutes.value}`;
  if (props.hasSeconds) {
    formatted += `:${getSeconds.value}`;
  }
  return formatted;
});
</script>
